<template>
    <slot />
</template>

<script lang="ts" setup>
import { type CoreUiFormRadioGroupProvide, SymbolCoreUiFormRadioGroup } from '@core/app/composables/components'
import type { FormFieldObject } from '@core-types/form'

const inputValue = defineModel<string | number | null | undefined>()
const formInputValue = defineModel<FormFieldObject<boolean | number | string | null>>('form')

const groupId = useId() ?? ''

provide<CoreUiFormRadioGroupProvide>(SymbolCoreUiFormRadioGroup, {
    name: groupId,
    inputValue: inputValue,
    formInputValue: formInputValue,
    isRadioChecked: (value) => {
        if (inputValue.value) return value === inputValue.value
        return value === formInputValue.value?.__v
    },
})

</script>
